<template>
  <section id="performance" class="page-container">
    <!-- Desktop graph -->
    <div class="mt-48 hidden lg:flex justify-center gap-x-4">
      <div class="relative">
        <video
          class="w-[480px] h-[480px] rounded-3xl"
          autoplay
          loop
          muted
          :controls="false"
          playsinline
          src="/web/images/landing-page/videos/productivity.mp4"
        ></video>
        <div class="absolute -top-5 left-9" v-motion-roll-visible-once-bottom>
          <span
            class="w-80 h-24 p-6 bg-indigo-500 rounded-full text-sm text-white text-xl -rotate-6 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/productivity-icon.svg"
              alt="More productivity icon"
            />
            100% {{ $t("landingPage.performance.productive") }}
          </span>
        </div>
        <div class="absolute top-28 -left-16" v-motion-roll-visible-once-bottom>
          <span
            class="w-80 h-24 p-6 bg-indigo-500 rounded-full text-sm text-white text-xl rotate-3 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/profit-icon.svg"
              alt="Money icon"
            />
            {{ $t("landingPage.performance.profit") }}
          </span>
        </div>
        <div
          class="absolute bottom-8 -left-12"
          v-motion-roll-visible-once-bottom
        >
          <span
            class="w-60 h-24 p-6 bg-indigo-500 rounded-full text-sm text-white text-xl rotate-12 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/insights-icon.svg"
              alt="Ideias icons"
            />
            {{ $t("landingPage.performance.insights") }}
          </span>
        </div>
      </div>
      <div class="w-[700px]">
        <h2
          class="w-fit h-11 py-2 px-4 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-semibold"
          v-motion-slide-visible-once-left
        >
          {{ $t("landingPage.performance.enhancePerformance") }}
        </h2>
        <h3
          class="font-bold text-xl lg:text-5xl text-center my-10"
          v-motion-slide-visible-once-right
        >
          {{ $t("landingPage.performance.title") }}.
        </h3>
        <p
          class="mt-8 mx-auto max-w-sm lg:max-w-xl w-full text-xl lg:text-4xl text-center text-gray-500"
          v-motion-slide-visible-once-left
        >
          {{ $t("landingPage.performance.description") }}.
        </p>
      </div>
    </div>

    <!-- Mobile graph -->
    <div class="lg:hidden">
      <h2
        class="w-fit h-11 py-2 px-4 mt-24 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-bold lg:hidden"
      >
        {{ $t("landingPage.performance.enhancePerformance") }}
      </h2>
      <h3 class="font-bold text-3xl md:text-5xl text-center mt-6">
        {{ $t("landingPage.performance.title") }}.
      </h3>
      <p
        class="mt-8 mx-auto max-w-sm lg:max-w-xl w-full text-xl lg:text-4xl text-center text-gray-500"
      >
        {{ $t("landingPage.performance.description") }}.
      </p>
      <div class="relative max-w-sm mx-auto">
        <video
          class="rounded-3xl mt-16 mx-auto px-3 w-96"
          autoplay
          loop
          muted
          :controls="false"
          playsinline
          src="/web/images/landing-page/videos/productivity.mp4"
        ></video>
        <!-- <img class="mt-16 mx-auto px-3 w-96" src="/web/images/landing-page/graph.png" alt="" /> -->
        <div class="absolute -top-5 right-0" v-motion-roll-visible-once-bottom>
          <span
            class="w-64 h-16 p-6 bg-indigo-500 rounded-full text-sm text-white -rotate-6 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/productivity-icon.svg"
              alt="More productivity icon"
            />
            100% {{ $t("landingPage.performance.productive") }}.
          </span>
        </div>
        <div class="absolute top-20 left-0" v-motion-roll-visible-once-bottom>
          <span
            class="w-60 h-16 p-6 bg-indigo-500 rounded-full text-sm text-white rotate-3 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/profit-icon.svg"
              alt="Money icon"
            />
            {{ $t("landingPage.performance.profit") }}
          </span>
        </div>
        <div
          class="absolute -bottom-5 left-0"
          v-motion-roll-visible-once-bottom
        >
          <span
            class="w-52 h-16 p-6 bg-indigo-500 rounded-full text-sm text-white -rotate-6 flex items-center justify-center"
          >
            <img
              class="me-1 w-7"
              src="/web/images/landing-page/insights-icon.svg"
              alt="ideias icon"
            />
            {{ $t("landingPage.performance.insights") }}
          </span>
        </div>
        <LandingPageBlueCircle
          width="264px"
          height="264px"
          blur="320px"
          class="left-80 -z-10"
        ></LandingPageBlueCircle>
      </div>
    </div>
  </section>
</template>
