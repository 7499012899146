<template>
  <div
    class="py-6 border-b-2 border-slate-900 pb-6 z-10 fixed top-0 right-0 left-0 w-full bg-[#02070d] shadow-2xl"
  >
    <div class="container mx-auto flex items-center justify-between">
      <DefaultButton size="fit" color="none" @click="goToSection('#home')">
        <img
          class="w-20 lg:w-32 ms-6"
          src="/web/images/landing-page/logo.svg"
          alt="Reality Studio Logo"
        />
      </DefaultButton>
      <DefaultButton
        v-if="!menuIsOpen"
        size="fit"
        color="none"
        class="lg:hidden me-6"
        @click="toggleMenu"
        v-motion-fade-visible-once
      >
        <span class="material-icons text-3xl">menu</span>
      </DefaultButton>
      <nav class="hidden lg:block me-6">
        <ul
          class="text-center text-xl font-semibold flex flex-col gap-y-8 lg:flex-row lg:gap-x-8"
        >
          <li
            v-for="(content, i) in listContent"
            :key="i"
            class="text-gray-300 hover:text-white transition-all ease-in-out cursor-pointer"
            v-motion-pop
            :delay="20 * (i + 1)"
          >
            <DefaultButton
              @click="goToSection(content.sectionId)"
              size="fit"
              color="none"
            >
              {{ $t(content.title) }}
            </DefaultButton>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <nav
    v-if="menuIsOpen"
    class="w-screen h-screen bg-gray-950 absolute z-10 top-0 left-0 lg:hidden transition-opacity delay-200 ease-in-out"
  >
    <LandingPageBlueCircle
      width="12rem"
      height="12rem"
      blur="150px"
      class="-z-10"
    />

    <div class="lg:hidden border-b-2 border-slate-900 pb-8">
      <div class="flex my-6 justify-between items-center page-container">
        <p class="text-3xl font-medium">Menu</p>
        <div>
          <DefaultButton
            size="fit"
            color="none"
            class="block"
            @click="toggleMenu"
          >
            <span class="material-icons text-3xl">close</span>
          </DefaultButton>
        </div>
      </div>
      <div class="page-container">
        <DefaultButton
          @click="goToHome"
          class="mx-auto mt-10 rounded-3xl font-bold"
        >
          {{ $t("landingPage.getStarted") }}
          <span class="material-icons ms-1 font-bold">open_in_new</span>
        </DefaultButton>
      </div>
    </div>
    <ul class="mt-8 text-center text-2xl font-semibold flex flex-col gap-y-8">
      <li
        v-for="(content, index) in listContent"
        :key="index"
        class="text-gray-300 hover:text-white mx-auto"
        v-motion-pop
        :delay="20 * (index + 1)"
      >
        <DefaultButton
          class="font-bold text-white text-2xl"
          color="none"
          size="fit"
          @click="
            () => {
              menuIsOpen = false;
              scrollBlock();
              goToSection(content.sectionId);
            }
          "
        >
          {{ $t(content.title) }}
        </DefaultButton>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const router = useRouter();

const menuIsOpen = ref<boolean>(false);

const listContent = [
  { title: "landingPage.menuNav.aiTools", sectionId: "#aiTools" },
  { title: "landingPage.menuNav.performance", sectionId: "#performance" },
  { title: "landingPage.menuNav.solutions", sectionId: "#solutions" },
  { title: "landingPage.menuNav.pricing", sectionId: "#pricing" },
  { title: "FAQ", sectionId: "#faq" },
];

const toggleMenu = () => {
  menuIsOpen.value = !menuIsOpen.value;
  scrollBlock();
};

const scrollBlock = () => {
  document.body.style.position = menuIsOpen.value ? "fixed" : "relative";
};

const goToSection = (sectionSelected: string) => {
  const destiny = document.querySelector(sectionSelected) as HTMLElement;
  if (destiny) {
    window.scrollTo({
      top: destiny.offsetTop - 100,
      behavior: "smooth",
    });
  }
};

const goToHome = () => {
  menuIsOpen.value = false;
  scrollBlock();
  router.push("/my-account");
};
</script>
